import React, {useState, useEffect} from 'react'
import { BASE_URL } from '../../../components/common/AppConfig';
import { handleDynamicRequestHeader } from '../../../components/common/DyanamicRequest';
import { ChevronRight} from 'react-bootstrap-icons';
import users from '../../../assets/images/users.png'
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setActiveLink } from '../../../store/Link/action';
import { setWebinar } from '../../../store/Career/action';

function WebinarMaster({ displayCount } ) {
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
    const [webinarData, setwebninarData] = useState([]);
    const userToken = useSelector((state) => state.roleReducer.jwtToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fetchWebinarData = async () => {
        const requestBody = {};
        const method = 'POST';
        const url = `${BASE_URL}/mobile/getAllWebiners`;
        const token = `${userToken}`;
        const wrongResponse = () => {
            setAlertMessage({ success: '', error: 'Unable to fetch parameters' });
        }
        const rightResponse = (data) => {
          
                setwebninarData(data.webiners);
               }
        handleDynamicRequestHeader(method, url, requestBody, token, wrongResponse, rightResponse)
    }
      
      useEffect(() => {
        fetchWebinarData();
        webinarData.map((webinar)=>webinar.Date)
      }, []);

   
    const handleWebiner=()=>{
        dispatch(setActiveLink('Activity Center'));
        
    }
    const handleWebinerSingle=(id)=>{
        dispatch(setWebinar(id))
        console.log(id)

        navigate('/activity/webinar/details')
    }
    const displayWebinars = displayCount ? webinarData.slice(0, displayCount) : webinarData;

    return (
       
        <div className="webinar">
             {displayWebinars.length>0 && (
            <div className="row">
                <div
                    className="section-header d-md-flex justify-content-md-between align-items-md-center">
                    <h2><span>Free webinars</span> curated for you</h2>
                    {displayCount>3 ?(
                        <></>
                    ):(
                        <Link to="/activity/webinar" onClick={handleWebiner}>View all webinars  <ChevronRight /></Link>
                    )}
                   
                </div>
                { displayWebinars.map((webinar, index) => (
                     <div key={index} className="col-md-6 mb-lg-0 mb-sm-4 mb-4 mt-4">
                     <div className="webinar-img" style={{cursor:'pointer'}} onClick={()=>{handleWebinerSingle(webinar._id)}}>
                         <div className="text-time-date text-white">
                             {webinar.Date} <span>|</span> {webinar.StartTime}
                         </div>
                         <img src={webinar.WebinerPic} className="img-fluid webinar-banner-img" style={{ width: '100%', height:'35vh' }} />
                         <div className="webinar-content col-md-12 d-sm-flex justify-content-sm-between py-4 px-4">
                             <div className="webinar-name col-sm-6 col-12 mb-sm-0 mb-3">
                                 <h3 className="mb-2 mb-md-0">{webinar.WebinerName}</h3>
                                 <p>{webinar.lectureBy}</p>
                             </div>
                             {/* <div className="webinar-name col-sm-6 col-12 mb-sm-0 mb-3 text-sm-end">
                                 <div className="webinarUsers mb-2 mb-md-0">
                                     <img src={users} className="img-fluid" alt="Users" />
                                 </div>
                                 <p>1.5k+ interested</p>
                             </div> */}
                         </div>
                     </div>
                 </div>
                ))}
            </div>
                 )}
        </div>
   

    )
}

export default WebinarMaster
