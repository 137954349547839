import React, { useEffect, useState, useRef } from 'react';
import counsllor_view_list from '../../../assets/images/counsllor-view-list.png';
import counsllor_graph from '../../../assets/images/counsllor-graph.png';
import personality from '../../../assets/images/personality-assesment-skills.png';
import c_user from '../../../assets/images/c-user-personality.png';
import { handleDynamicRequestHeader } from '../../../components/common/DyanamicRequest';
import { BASE_URL } from '../../../components/common/AppConfig';
import { setActiveLink } from '../../../store/Link/action';
import { useDispatch } from 'react-redux';
import Chart from 'chart.js/auto';
import SpacebugsImg from '../../../assets/images/earnings_bg.png';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const renderPieChart = (chartRef, data, width = 400, height = 400) => {
    const ctx = chartRef.current.getContext('2d');

    // Destroy any existing chart on the canvas
    Chart.getChart(ctx)?.destroy();

    ctx.canvas.width = width;
    ctx.canvas.height = height;

    new Chart(ctx, {
        type: 'doughnut',
        data: data,
        options: {
            // Add any options you need here
        }
    });
};


function PartnerAnalytics({ data }) {

    const [partnerPerformance, setPartnerPerformance] = useState([]);
    const userToken = useSelector((state) => state.roleReducer.jwtToken);
    const activeLink = useSelector((state) => state.linkReducer.activeLink);
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
    const chartRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        handleStudent();
    }, []);


    useEffect(() => {
        if (partnerPerformance && chartRef.current) {
            const data = {

                datasets: [{

                    data: [partnerPerformance.numberof5star || 0.1, partnerPerformance.numberof4star || 0.1, partnerPerformance.numberof3star || 0.1, partnerPerformance.total_review - partnerPerformance.numberof5star - partnerPerformance.numberof4star - partnerPerformance.numberof3star || 0.1],
                    // data: [3, 4, 5, 7],
                    backgroundColor: [
                        '#2CAF49',
                        '#FF8B01',
                        '#FF5757',
                        '#369FFF',
                    ]
                }]
            };
            renderPieChart(chartRef, data);
        }
    }, [partnerPerformance]);


    const handleStudent = async () => {
        const requestBody = {};
        const method = 'POST';
        const url = `${BASE_URL}/partner/performancePage`;
        const token = userToken;

        const wrongResponse = () => {
            setAlertMessage({ success: '', error: 'Unable to fetch parameters' });
        }
        const rightResponse = (data) => {
            setPartnerPerformance(data)

        }
        handleDynamicRequestHeader(method, url, requestBody, token, wrongResponse, rightResponse)
    }

    const handleCLick = () => {
        dispatch(setActiveLink('Student List'));
        navigate('/student-list');


    }

    console.log(partnerPerformance)
    return (
        <>
            <div class="col-lg-12">

                <section class="counsellor-home">

                    <div class="row">
                        {partnerPerformance &&
                            <>
                                <div class="col-lg-6">
                                    <div class="counsellor-view">
                                        <h3>Total Tasks</h3>
                                        <img src={counsllor_view_list} class="counsllor-view-list" alt="" />
                                        <span>{partnerPerformance?.tasks}</span>
                                        <p><img src={counsllor_graph} alt="" /><strong>7% up</strong> from last the
                                            month</p>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="counselling-view p-4">
                                        <div class="user-box-con">
                                            <div>
                                                <h4>Average Review</h4>
                                                <h1 className='text-white'>{partnerPerformance?.avg}/5</h1>
                                            </div>
                                        </div>
                                        <div class="date-know-more">
                                            <span className='text-white pb-2 pt-2'> <img src={counsllor_graph} alt="" /><strong>3 Recent </strong> reviews added </span>
                                        </div>
                                    </div>
                                </div>
                            </>}


                    </div>

                </section>


                <section class="counsellor-analytics">

                    <h4 className='mb-3'><strong>Task Analytics</strong></h4>

                    <div class="row">

                        <div class="col-lg-12">
                            {/* <div class="counsellor-analytics-heading-dates">
                <h2>Student Analytics</h2>
                <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                    <div class="btn-group me-2" role="group" aria-label="">
                        <button type="button" class="btn">Weekly</button>
                    </div>
                    <div class="btn-group me-2" role="group" aria-label="">
                        <button type="button" class="btn">Monthly</button>
                    </div>
                    <div class="btn-group me-2" role="group" aria-label="">
                        <button type="button" class="btn active">Yearly</button>
                    </div>
                </div>
            </div> */}
                        </div>
                        {partnerPerformance &&
                            <>
                                <div class="col-lg-4">
                                    <div class="personality-assesment">
                                        <h2>Task Completed</h2>
                                        <div class="d-flex justify-content-between">
                                            <h3>{partnerPerformance?.complete}</h3>
                                            <img class="blend-img-counslr" src={personality} alt="" />
                                        </div>
                                        <h4><strong>84%</strong> positive feedback</h4>
                                        <a href="#" className="c-view" data-bs-toggle="modal"
                                            data-bs-target="#viewAssesment">View </a>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="personality-assesment bg-parents">
                                        <h2>Incomplete Tasks</h2>
                                        <div class="d-flex justify-content-between">
                                            <h3>{partnerPerformance?.incomplete}</h3>
                                            <img class="blend-img-counslr" src={personality} alt="" />
                                        </div>

                                        <h4><strong>5000$</strong> worth opportunities missed</h4>
                                        <a href="#" class="c-view">View</a>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="personality-assesment bg-discovery">
                                        <h2>Opportunity Given Up</h2>
                                        <div class="d-flex justify-content-between">
                                            <h3>{partnerPerformance?.givenup}</h3>
                                            <img class="blend-img-counslr" src={personality} alt="" />
                                        </div>

                                        <h4><strong>5000$</strong> worth opportunities given up</h4>
                                        <a href="#" class="c-view">View</a>
                                    </div>
                                </div>
                            </>}
                    </div>

                </section>

                {partnerPerformance && <>
                    <div className='row mt-3 '>

                        <div class="counsellor-types-students mt-4  col-lg-7">
                            <div class="row ps-3 pe-3">
                                <div class="col-lg-12">
                                    <div class="c-type-student">
                                        <h3>Reviews Collected</h3>
                                        {/* <a onClick={handleCLick} class="c-view-list" style={{ cursor: 'pointer' }}>View List</a> */}
                                    </div>
                                </div>

                                <div class="col-lg-7">
                                    <div class="c-pie-chart">
                                        {/* <img src={conslr} alt="" /> */}
                                        <canvas ref={chartRef} />
                                    </div>
                                </div>

                                <div class="col-lg-5">
                                    <div className='c-pie-chart-data'>
                                        <div class="col">

                                            <div>
                                                <div className='chart-data'>
                                                    <div className='head color-creator'>{partnerPerformance.numberof5star}</div>
                                                    <span> 5 Star Reviews</span>
                                                </div>
                                            </div>

                                            <div>
                                                <div className='chart-data'>
                                                    <div className='head color-organiser'>{partnerPerformance.numberof4star}</div>
                                                    <span>4 Star Reviews</span>
                                                </div>
                                            </div>

                                            <div>
                                                <div className='chart-data'>
                                                    <div className='head color-helper'>{partnerPerformance.numberof3star}</div>
                                                    <span>3 Star Reviews</span>
                                                </div>
                                            </div>

                                            <div>
                                                <div className='chart-data'>
                                                    <div className='head color-leader'>{partnerPerformance.total_review - partnerPerformance.numberof5star - partnerPerformance.numberof4star - partnerPerformance.numberof3star}</div>
                                                    <span>&lt; 3 Star Reviews</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-5'>
                            <div className='Spacebugs-right-img'>
                                <span className='text-center' >Rs {partnerPerformance?.earning}</span>
                                <img src={SpacebugsImg} alt="" />
                            </div>
                        </div>

                    </div>

                </>}
            </div>

            <div class="modal fade viewAssesment" id="viewAssesment" tabIndex="-1" aria-labelledby="viewAssesment"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content" id="custom-modal-content">

                        <div class="modal-header">
                            <h5 class="modal-title">Personality Assesment</h5>
                            <button type="button" class="btn-close close-assesment" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div class="modal-body">

                            <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                                        aria-selected="true">Completed</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                                        aria-selected="false">Pending</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="pills-tabContent">

                                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                                    <div class="tab-c-user-personality">
                                        <img src={c_user} alt="" />
                                        <div>
                                            <h3>Templeton Peck</h3>
                                            <p>10th - section A | St. Xavier School</p>
                                        </div>
                                        <a href="#" class="c-arow"></a>
                                    </div>

                                    <div class="tab-c-user-personality">
                                        <img src={c_user} alt="" />
                                        <div>
                                            <h3>Templeton Peck</h3>
                                            <p>10th - section A | St. Xavier School</p>
                                        </div>
                                        <a href="#" class="c-arow"></a>
                                    </div>

                                    <div class="tab-c-user-personality">
                                        <img src={c_user} alt="" />
                                        <div>
                                            <h3>Templeton Peck</h3>
                                            <p>10th - section A | St. Xavier School</p>
                                        </div>
                                        <a href="#" class="c-arow"></a>
                                    </div>


                                </div>

                                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">

                                    <div class="tab-c-user-personality">
                                        <img src={c_user} alt="" />
                                        <div>
                                            <h3>Templeton Peck</h3>
                                            <p>10th - section A | St. Xavier School</p>
                                        </div>
                                        <a href="#" class="c-arow"></a>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}

export default PartnerAnalytics
