import React, { useEffect, useState } from 'react';
import counsllor_view_list from '../../../assets/images/counsllor-view-list.png';
import counsllor_graph from '../../../assets/images/counsllor-graph.png';
import counsllor_clock from '../../../assets/images/counsler-clock.png';
import { handleDynamicRequestHeader } from '../../../components/common/DyanamicRequest';
import { BASE_URL } from '../../../components/common/AppConfig';
import { setActiveLink } from '../../../store/Link/action';
import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';


function PartnerHome({ data }) {

    const [partnerData, setPartnerData] = useState([]);
    const userToken = useSelector((state) => state.roleReducer.jwtToken);
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        fetchPartner();
        // handleSchedule();
    }, []);


    useEffect(() => {
        if (partnerData && partnerData.complete_count) {
        }
    }, [partnerData]);


    const fetchPartner = async () => {
        const requestBody = {};
        const method = 'POST';
        const url = `${BASE_URL}/partner/dashboard`;
        const token = userToken;

        const wrongResponse = () => {
            setAlertMessage({ success: '', error: 'Unable to fetch parameters' });
        }
        const rightResponse = (data) => {
            setPartnerData(data)

        }
        handleDynamicRequestHeader(method, url, requestBody, token, wrongResponse, rightResponse)
    }

    function formatDate(dateString) {
        if (!dateString) return "Invalid date"; // Handling null input

        const [year, month, day] = dateString.split('-');
        const date = new Date(year, month - 1, day);
        const formattedDay = date.getDate();
        const formattedMonth = date.toLocaleString('default', { month: 'short' });
        const formattedYear = date.getFullYear();
        return `${formattedDay} ${formattedMonth} ${formattedYear}`;
    }

    const handleTask = ({ taskid }) => {
        dispatch(setActiveLink('Task Master'));
        navigate(`/partner/task?taskid=${taskid}`);

    }

    const interestedtask = ({ taskid }) => {

        const requestBody = { id: taskid };
        const method = 'POST';
        const url = `${BASE_URL}/partner/addTask`;
        const token = userToken;

        const wrongResponse = () => {
            setAlertMessage({ success: '', error: 'Adding task failed' });
        }
        const rightResponse = (data) => {
            fetchPartner(data)

        }
        handleDynamicRequestHeader(method, url, requestBody, token, wrongResponse, rightResponse)
    }

    const giveUpTask = async ({ taskid }) => {
        const requestBody = { id: taskid };
        const method = 'POST';
        const apiUrl = `${BASE_URL}/partner/clickGiveup`;

        const token = await userToken;
        const wrongResponse = () => {
            setAlertMessage({ success: '', error: 'Unable to give up task' });
        }
        const rightResponse = (data) => {
            fetchPartner()
        };
        handleDynamicRequestHeader(method, apiUrl, requestBody, token, wrongResponse, rightResponse)
    }

    return (
        <>
            <div class="col-lg-12">

                <section class="counsellor-home">

                    <div class="row">
                        {partnerData ? (<>
                            <div class="col-lg-6">
                                <div class="counsellor-view">
                                    <h3>Total Gigs Completed</h3>
                                    <img src={counsllor_view_list} class="counsllor-view-list" alt="" />
                                    <span>{partnerData?.complete_count}</span>
                                    <p><img src={counsllor_graph} alt="" /><strong>7% up </strong> from last the
                                        month</p>
                                </div>
                            </div>

                            {partnerData?.upcoming &&
                                <div class="col-lg-6">
                                    <div class="counselling-view">z
                                        <h3 class="box-heading">Upcoming <span>onboarding</span> <a href={`/partner/task?taskid=${partnerData?.upcoming?._id}`}
                                            class="onclick-action"></a></h3>
                                        <div class="user-box-con">
                                            <img src={partnerData?.upcoming?.pic} alt="partner" />
                                            <div>
                                                <h4>{partnerData?.upcoming?.schoolname}</h4>
                                                <p>{partnerData?.upcoming?.address}</p>
                                            </div>
                                        </div>
                                        <div class="date-know-more">
                                            <p><img src={counsllor_clock} alt="" />{formatDate(partnerData?.upcoming?.Date)} </p>
                                            <a href={`/partner/task?taskid=${partnerData?.upcoming?._id}`}>Know more</a>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>) : (<></>)}
                    </div>
                </section>


                <div class="UpcomingSchedules">
                    <div class="container">

                        <div class="row">
                            <div class="col-lg-12 padding-mobile-none">

                                <div class="sec-heading">
                                    <h3>Available Tasks </h3>

                                </div>
                                {partnerData?.alljobs && partnerData?.alljobs.map((task) => (

                                    <div class="schedulingDetails row">
                                        {/* <div class="scheduled-time-day">
                                                <h3> 7 Nov </h3>
                                                <time>4:00 pm</time>
                                            </div> */}
                                        <div class="CallScheduled_With col-8 tw-cursor-pointer" onClick={() => handleTask({ taskid: task._id })}>
                                            <div class="scheduler_img">
                                                <img src={task?.pic} className="img-fluid" alt="Schedule" />
                                            </div>
                                            <h5> {task?.schoolname}
                                                <br></br>
                                                <span>{task?.address}</span>
                                            </h5>
                                        </div>
                                        <div class="call_block col-4">
                                            <button type="button" class="btn btn-outline-primary" onClick={() => interestedtask({ taskid: task._id })}>Interested</button>

                                            {/* <Dropdown>
                                                <Dropdown.Toggle className="custom-toggle" as={CustomToggle} id="dropdown-custom-components">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                                    </svg>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="" onClick={() => giveUpTask({ taskid: task._id })}>Give Up</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown> */}
                                        </div>


                                    </div>

                                ))}

                                {/* <div class="schedulingDetails Active">
                                <div class="scheduled-time-day">
                                    <h3> Today </h3>
                                    <time>2:00 pm</time>
                                </div>
                                <div class="CallScheduled_With">
                                    <div class="scheduler_img">
                                        <img src="images/Scheduler1.jpg" class="img-fluid" />
                                    </div>
                                    <h5>
                                        Malvika D. Rane<br></br>
                                        <span>10th - section A | St. Xavier School</span>
                                    </h5>
                                </div>
                                <div class="call_block">
                                    <button type="button" class="btn btn-primary">Join Now</button>
                                    <i class="bi bi-three-dots-vertical"></i>
                                </div>

                            </div> */}

                            </div>

                        </div>

                    </div>
                </div>

            </div >
        </>

    )
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
        </svg>
    </a>
));

export default PartnerHome
