import React, { useEffect, useState } from 'react';
import counsllor_view_list from '../../../assets/images/counsllor-view-list.png';
import counsllor_graph from '../../../assets/images/counsllor-graph.png';
import counsllor_clock from '../../../assets/images/counsler-clock.png';
import { handleDynamicRequestHeader } from '../../../components/common/DyanamicRequest';
import { BASE_URL } from '../../../components/common/AppConfig';
import { setActiveLink } from '../../../store/Link/action';
import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';


function WalletDetails({ data }) {

    const [wallet, setWallet] = useState([]);
    const userToken = useSelector((state) => state.roleReducer.jwtToken);
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);

    const requestWithdrawal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleWithdrawalSubmit = (amount) => {
        // Handle withdrawal submission here
        console.log("Withdrawal amount:", amount);

        const requestBody = {amount};
        const method = 'POST';
        const url = `${BASE_URL}/partner/requestWithdraw`;
        const token = userToken;

        const wrongResponse = () => {
            setAlertMessage({ success: '', error: 'Unable to fetch parameters' });
        }
        const rightResponse = (data) => {
            setAlertMessage({ success: data.msg, error: '' });

        }
        handleDynamicRequestHeader(method, url, requestBody, token, wrongResponse, rightResponse)

        // Close the modal after submission
        setShowModal(false);
    };


    useEffect(() => {
        fetchPartner();
        // handleSchedule();
    }, []);


    useEffect(() => {
        if (wallet && wallet.complete_count) {
        }
    }, [wallet]);


    const fetchPartner = async () => {
        const requestBody = {};
        const method = 'POST';
        const url = `${BASE_URL}/partner/walletPage`;
        const token = userToken;

        const wrongResponse = () => {
            setAlertMessage({ success: '', error: 'Unable to fetch parameters' });
        }
        const rightResponse = (data) => {
            setWallet(data)

        }
        handleDynamicRequestHeader(method, url, requestBody, token, wrongResponse, rightResponse)
    }

    function formatDate(dateString) {
        if (!dateString) return "Invalid date"; // Handling null input

        const [year, month, day] = dateString.split('-');
        const date = new Date(year, month - 1, day);
        const formattedDay = date.getDate();
        const formattedMonth = date.toLocaleString('default', { month: 'short' });
        const formattedYear = date.getFullYear();
        return `${formattedDay} ${formattedMonth} ${formattedYear}`;
    }

    const handleTask = ({ taskid }) => {
        dispatch(setActiveLink('Task Master'));
        navigate(`/partner/task?taskid=${wallet?.upcoming?._id}`);

    }



    return (
        <>
            <div class="col-lg-12">

                <section class="counsellor-home">

                    <div class="row">
                        {wallet ? (<>
                            <div class="counsellor-view px-5 ">
                                <div className='row'>
                                    <h3 className='col-lg-9 col-7'>Wallet Balance</h3>
                                    <button className='btn btn-primary fw-semibold col-lg-3 col-5' onClick={requestWithdrawal} >Request Withdrawal</button>
                                </div>
                                {/* <img src={counsllor_view_list} class="counsllor-view-list" alt="" /> */}
                                <span>Rs {wallet?.wallet}</span>
                                <p><img src={counsllor_graph} alt="" /><strong>7% up </strong> from last the
                                    month</p>

                            </div>
                        </>) : (<></>)}
                    </div>
                </section>
                {showModal && <WithdrawalModal onClose={handleCloseModal} onSubmit={handleWithdrawalSubmit} />}


                <div class="UpcomingSchedules">
                    <div class="container">

                        <div class="row">
                            <div class="col-lg-12 padding-mobile-none">

                                <div class="sec-heading">
                                    <h3>All Transactions </h3>

                                </div>
                                {wallet && wallet?.allTransactions && wallet.allTransactions.map((transaction) => (

                                    <div class="schedulingDetails">
                                        {/* <div class="scheduled-time-day">
                                                <h3> 7 Nov </h3>
                                                <time>4:00 pm</time>
                                            </div> */}
                                        <div class="CallScheduled_With" onClick={() => handleTask({ taskid: transaction._id })}>
                                            <h5> {transaction?.keyword}
                                                <br></br>
                                                <span>{transaction?.Date}</span>
                                            </h5>
                                        </div>
                                        <div>
                                            <p className='fw-bold'>Rs.{transaction?.amount}</p>
                                        </div>
                                        <div>
                                            <p class={transaction?.completed ? 'text-success fw-bold' : 'text-warning fw-bold '}>{transaction?.completed ? 'Success' : 'Requested'}</p>
                                        </div>

                                    </div>

                                ))}

                                {/* <div class="schedulingDetails Active">
                                <div class="scheduled-time-day">
                                    <h3> Today </h3>
                                    <time>2:00 pm</time>
                                </div>
                                <div class="CallScheduled_With">
                                    <div class="scheduler_img">
                                        <img src="images/Scheduler1.jpg" class="img-fluid" />
                                    </div>
                                    <h5>
                                        Malvika D. Rane<br></br>
                                        <span>10th - section A | St. Xavier School</span>
                                    </h5>
                                </div>
                                <div class="call_block">
                                    <button type="button" class="btn btn-primary">Join Now</button>
                                    <i class="bi bi-three-dots-vertical"></i>
                                </div>

                            </div> */}

                            </div>

                        </div>

                    </div>
                </div>

            </div >
        </>

    )
}

function WithdrawalModal({ onClose, onSubmit }) {
    const [withdrawalAmount, setWithdrawalAmount] = useState('');

    const handleAmountChange = (e) => {
        setWithdrawalAmount(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(withdrawalAmount);
    };

    return (
        <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog bg-white " role="document">
                <div className="modal-content" style={{ backgroundColor: '#fff', borderRadius: '10px' }}>
                    <div className="modal-header row">
                        <h5 className="modal-title col-lg-10 col-10" style={{ color: '#333' }}>Request Withdrawal</h5>
                        <button type="button" className="close col-lg-1 col-1 tw-w-fit" onClick={onClose} style={{ color: '#333' }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="withdrawalAmount" style={{ color: '#333' }}>Enter Withdrawal Amount:</label>
                                <input type="number" className="form-control" id="withdrawalAmount" min="0" value={withdrawalAmount} onChange={handleAmountChange} required />
                            </div>
                            <button type="submit" className="btn btn-primary mt-3 pt-3">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    );
}


export default WalletDetails
