import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import Navbar from '../../components/Navbar/Navbar';
import CommonLayout from '../../components/common/CommonLayout';
import Loader from '../../components/common/Loader';
import WalletDetails from './Analytics Master/WalletDetails';
function PartnerWallet() {
  const [isLoader, setIsLoader] = useState(true);

  const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });

  useEffect(() => {
    setTimeout(() => {
      setIsLoader(false);
    }, 2000);
  })

  return (
    <>
      {isLoader ? (

        <Loader />
      ) : (
        <CommonLayout>
          <div className="left-navigation">
            <Sidebar></Sidebar>
          </div>
          <div className="right-content">
            <Navbar></Navbar>
            <WalletDetails />
          </div>
        </CommonLayout>

      )}
    </>
  )
}

export default PartnerWallet
