import React, { useState, useEffect } from 'react';
import Boat from '../../assets/images/boat.png'
import Logo from '../../assets/images/logo.png'
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setActiveLink } from '../../store/Link/action';
import { useSelector } from 'react-redux';
import { setDisplayOff } from '../../store/Link/action';
function Sidebar() {
    const activeLink = useSelector((state) => state.linkReducer.activeLink);
    //const storedNavItem = localStorage.getItem('activeNavItem');
    const [activeNavItem, setActiveNavItem] = useState(activeLink || 'Home');
    const userRole = useSelector((state) => state.roleReducer.userRole);
    const dispatch = useDispatch();
    const handleNavItemClick = (itemName) => {
        dispatch(setActiveLink(itemName));
        setActiveNavItem(itemName);
        //  localStorage.setItem('activeNavItem', itemName);
    };

    const handleDisplay = () => {
        dispatch(setDisplayOff(false));
    }
    const handleClose = () => {
        dispatch(setDisplayOff(true));
    }
    useEffect(() => {
    }, [activeNavItem]);

    // const navItems = [
    //     { name: 'Home', label: 'Home', linkTo: '/dashboard', className: 'nav-link-home' },
    //     { name: 'Career Report', label: 'Career Report', linkTo: '/career', className: 'nav-link-career' },
    //     { name: 'Activity Center', label: 'Activity Center', linkTo: '/activity', className: 'nav-link-activity' },
    //     { name: 'Career Library', label: 'Career Library', linkTo: '/library', className: 'nav-link-library' },
    //  { name: 'SpaceBucks', label: 'SpaceBucks', linkTo: '/space-bucks', className: 'nav-link-spacebucks' },
    // ];

    // const navItems = [
    //     { name: 'Home', label: 'Home', linkTo: '/consoler', className: 'nav-link-home' },
    //     { name: 'Student List', label: 'Student List', linkTo: '/student-list', className: 'nav-link-career' },
    //     { name: 'Activity Center', label: 'Activity Center', linkTo: '/activity', className: 'nav-link-activity' },
    //     { name: 'Career Library', label: 'Career Library', linkTo: '/library', className: 'nav-link-library' },
    //     // { name: 'SpaceBucks', label: 'SpaceBucks', linkTo: '#', className: 'nav-link-spacebucks' },
    // ];

    let navItems;

    if (userRole.includes('ROLE_COUNSELLOR')) {
        navItems = [
            { name: 'Home', label: 'Home', linkTo: '/consoler', className: 'nav-link-home' },
            { name: 'Student List', label: 'Student List', linkTo: '/student-list', className: 'nav-link-career' },
            { name: 'Schedules', label: 'Schedules', linkTo: '/schedules', className: 'nav-link-spacebucks' },
            // { name: 'Activity Center', label: 'Activity Center', linkTo: '/activity', className: 'nav-link-activity' },
            { name: 'Career Library', label: 'Career Library', linkTo: '/library', className: 'nav-link-library' },

        ];
    } else if (userRole.includes('ROLE_FREELANCER')) {
        navItems = [
            { name: '', label: '', linkTo: '/partner', className: 'mobile-logo-new' },
            { name: 'Home', label: 'Home', linkTo: '/partner', className: 'nav-link-home' },
            { name: 'Assigned Tasks', label: 'Assigned Tasks', linkTo: '/assigned-list', className: 'nav-link-career' },
            { name: 'Performance', label: 'Performance', linkTo: '/partner/performance', className: 'nav-link-library' },
            { name: 'Wallet', label: 'Wallet', linkTo: '/partner/wallet', className: 'nav-link-spacebucks' },
            // { name: 'Activity Center', label: 'Activity Center', linkTo: '/activity', className: 'nav-link-activity' },
        ];
    }
    else {
        navItems = [
            { name: '', label: '', linkTo: '/dashboard', className: 'mobile-logo-new' },
            { name: 'Home', label: 'Home', linkTo: '/dashboard', className: 'nav-link-home' },
            { name: 'Career Report', label: 'Career Report', linkTo: '/career', className: 'nav-link-career' },
            { name: 'Activity Center', label: 'Activity Center', linkTo: '/activity', className: 'nav-link-activity' },
            { name: 'Career Library', label: 'Career Library', linkTo: '/library', className: 'nav-link-library' },
            { name: 'SpaceBucks', label: 'SpaceBucks', linkTo: '/space-bucks', className: 'nav-link-spacebucks' }
        ];
    }


    return (

        <>
            <nav className="navbar navbar-expand-md">
                <a className="navbar-brand" href="#">
                    <img className="logo" src={Logo} alt="" />
                </a>
                <button className="navbar-toggler" onClick={handleDisplay} type="button" data-bs-toggle="collapse"
                    data-bs-target="#mynavbar">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="mynavbar">
                    <button data-bs-toggle="collapse" data-bs-target="#mynavbar" className='navcllaps' onClick={handleClose}></button>
                    <ul className="nav-list-bar">
                        {navItems.map((item, index) => (

                            <li key={index} className={`nav-item ${activeNavItem === item.name ? 'active' : ''}`}>
                                <Link className={`nav-link ${item.className}`} to={item.linkTo}
                                    onClick={() => handleNavItemClick(item.name)}>
                                    {item.label}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </nav>
            <div id="demo" className="carousel slide left-nav-carousel" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="0"
                        className="active"></button>
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={Boat} alt="Boat Img" />
                        <div className="need-help">
                            <h2>Need help?</h2>
                            <p>Write to us and we’ll get back to you soon!</p>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={Boat} alt="Boat Img" />
                        <div className="need-help">
                            <h2>Need help?</h2>
                            <p>Write to us and we’ll get back to you soon!</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar
