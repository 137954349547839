import React, { useEffect, useState } from 'react'
import CommonLayout from '../../components/common/CommonLayout';
import Sidebar from '../../components/Sidebar/Sidebar';
import Navbar from '../../components/Navbar/Navbar';
import { handleDynamicRequestHeader } from '../../components/common/DyanamicRequest';
import { BASE_URL } from '../../components/common/AppConfig';
import { useSelector } from 'react-redux';
import Loader from '../../components/common/Loader';
import { Dropdown } from 'react-bootstrap';

function TaskList() {
    const [tasks, settasks] = useState([]);
    const userToken = useSelector((state) => state.roleReducer.jwtToken);
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
    const [isLoader, setIsLoader] = useState(true);
    const [time, setTime] = useState('month');
    const [taskType, setTaskType] = useState('Due');

    useEffect(() => {
        fetchTaskData();
    }, [taskType, time]);

    const fetchTaskData = async () => {
        // setIsLoader(true);
        const requestBody = {};
        const method = 'POST';
        const apiUrl = `${BASE_URL}/partner/getAllTasks`;

        const urlWithParams = new URL(apiUrl);
        urlWithParams.searchParams.append('time', time);
        urlWithParams.searchParams.append('taskType', taskType);

        const token = await userToken;
        const wrongResponse = () => {
            setAlertMessage({ success: '', error: 'Unable to fetch tasks' });
        }
        const rightResponse = (data) => {
            settasks(data.tasklist);
            // setIsLoader(false);
        };
        handleDynamicRequestHeader(method, urlWithParams, requestBody, token, wrongResponse, rightResponse)
    }

    useEffect(() => {
        setTimeout(() => {
            setIsLoader(false);
        }, 2000);
    });

    console.log(tasks)


    const giveUpTask = async ({ taskid }) => {
        const requestBody = { id: taskid };
        const method = 'POST';
        const apiUrl = `${BASE_URL}/partner/clickGiveup`;

        const token = await userToken;
        const wrongResponse = () => {
            setAlertMessage({ success: '', error: 'Unable to give up task' });
        }
        const rightResponse = (data) => {
            fetchTaskData();
        };
        handleDynamicRequestHeader(method, apiUrl, requestBody, token, wrongResponse, rightResponse)
    }

    const confirmTask = async ({ taskid }) => {
        const requestBody = { id: taskid };
        const method = 'POST';
        const apiUrl = `${BASE_URL}/partner/clickConfirm`;

        const token = await userToken;
        const wrongResponse = () => {
            setAlertMessage({ success: '', error: 'Unable to give up task' });
        }
        const rightResponse = (data) => {
            fetchTaskData()
        };
        handleDynamicRequestHeader(method, apiUrl, requestBody, token, wrongResponse, rightResponse)
    }


    return (
        <div>
            {isLoader ? (
                <Loader />
            ) : (
                <CommonLayout>
                    <div className="left-navigation">
                        <Sidebar></Sidebar>
                    </div>
                    <div className="right-content">
                        <Navbar></Navbar>

                        <>
                            <section className="profile-tab-nav">
                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <button className="nav-link active" id="nav-due-tab" data-bs-toggle="tab"
                                            data-bs-target="#nav-due" type="button" role="tab" aria-controls="nav-due"
                                            aria-selected="true" onClick={() => setTaskType('Due')} >Due Task</button>
                                        <button className="nav-link" id="nav-incomplete-tab" data-bs-toggle="tab"
                                            data-bs-target="#nav-incomplete" type="button" role="tab" aria-controls="nav-incomplete"
                                            aria-selected="false" onClick={() => setTaskType('Incomplete')}>Incomplete</button>
                                        <button className="nav-link" id="nav-completed-tab" data-bs-toggle="tab"
                                            data-bs-target="#nav-completed" type="button" role="tab" aria-controls="nav-completed"
                                            aria-selected="false" onClick={() => setTaskType('Complete')} >Completed Task</button>
                                    </div>
                                </nav>
                                <div className="d-flex justify-content-end pt-4 gap-2 ">
                                    <button className="btn btn-outline-primary" onClick={() => setTime('day')}>Day</button>
                                    <button className="btn btn-outline-primary" onClick={() => setTime('week')}>Week</button>
                                    <button className="btn btn-outline-primary" onClick={() => setTime('month')}>Month</button>
                                </div>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="profile-past-activities tab-pane fade show active" id="nav-due" role="tabpanel" aria-labelledby="nav-due-tab">
                                        {tasks && tasks.map((task) => {
                                            const taskDate = new Date(task.Date);
                                            const formattedDate = taskDate.toLocaleDateString('en-US', {
                                                day: '2-digit',
                                                month: 'short'
                                            });


                                            return <div class="schedulingDetails">
                                                <div class="scheduled-time-day">
                                                    <h3> {formattedDate} </h3>
                                                    <time>{task.time}</time>
                                                </div>
                                                <div class="CallScheduled_With">
                                                    <div class="scheduler_img">
                                                        <img src={task?.pic} class="img-fluid" />
                                                    </div>
                                                    <h5>
                                                        {task?.schoolname}<br></br>
                                                        <span>Class : {task.Class} | {task.address}</span>
                                                    </h5>
                                                </div>
                                                <div class="call_block">
                                                    <button type="button" onClick={() => confirmTask({ taskid: task._id })} class="btn btn-outline-primary px-2 ">Confirm Visit</button>
                                                    <Dropdown>
                                                        <Dropdown.Toggle className="custom-toggle" as={CustomToggle} id="dropdown-custom-components">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                                            </svg>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="" onClick={() => giveUpTask({ taskid: task._id })}>Give Up</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>

                                            </div>
                                        })}
                                    </div>
                                    <div className="tab-pane fade" id="nav-incomplete" role="tabpanel" aria-labelledby="nav-incomplete-tab">
                                        {tasks && tasks.map((task) => {
                                            const taskDate = new Date(task.Date);
                                            const formattedDate = taskDate.toLocaleDateString('en-US', {
                                                day: '2-digit',
                                                month: 'short'
                                            });


                                            return <div class="schedulingDetails">
                                                <div class="scheduled-time-day">
                                                    <h3> {formattedDate} </h3>
                                                    <time>{task.time}</time>
                                                </div>
                                                <div class="CallScheduled_With">
                                                    <div class="scheduler_img">
                                                        <img src={task?.pic} class="img-fluid" />
                                                    </div>
                                                    <h5>
                                                        {task?.schoolname}<br></br>
                                                        <span>Class : {task.Class} | {task.address}</span>
                                                    </h5>
                                                </div>

                                            </div>
                                        })}
                                    </div>
                                    <div className="tab-pane fade" id="nav-completed" role="tabpanel" aria-labelledby="nav-incomplete-tab">
                                        {tasks && tasks.map((task) => {
                                            const taskDate = new Date(task.Date);
                                            const formattedDate = taskDate.toLocaleDateString('en-US', {
                                                day: '2-digit',
                                                month: 'short'
                                            });


                                            return <div class="schedulingDetails">
                                                <div class="scheduled-time-day">
                                                    <h3> {formattedDate} </h3>
                                                    <time>{task.time}</time>
                                                </div>
                                                <div class="CallScheduled_With">
                                                    <div class="scheduler_img">
                                                        <img src={task?.pic} class="img-fluid" />
                                                    </div>
                                                    <h5>
                                                        {task?.schoolname}<br></br>
                                                        <span>Class : {task.Class} | {task.address}</span>
                                                    </h5>
                                                </div>

                                            </div>
                                        })}
                                    </div>
                                </div>
                            </section>
                        </>

                    </div>
                </CommonLayout>
            )
            }
        </div >
    )
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
        </svg>
    </a>
));

export default TaskList
