import React, { useEffect, useState } from 'react'
import CommonLayout from '../../components/common/CommonLayout';
import Sidebar from '../../components/Sidebar/Sidebar';
import Navbar from '../../components/Navbar/Navbar';
import { handleDynamicRequestHeader } from '../../components/common/DyanamicRequest';
import { BASE_URL } from '../../components/common/AppConfig';
import { useSelector } from 'react-redux';
import bulb from '../../assets/images/user-profile-bulb.png';
import profile_bugs from '../../assets/images/profile-bugs.png';
import profile_activity from '../../assets/images/profile-activiti.png';
import edit_form from '../../assets/images/edit-form.png';
import Loader from '../../components/common/Loader';
import SetAlert from '../../components/common/SetAlert';
import { useLocation, useNavigate } from 'react-router-dom';

function TaskMaster() {
    const [taskDetails, setTaskDetails] = useState([]);
    const userToken = useSelector((state) => state.roleReducer.jwtToken);
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
    const [isLoader, setIsLoader] = useState(true);
    const [pastActivity, setPassActivity] = useState([]);
    const navigate = useNavigate();
    const userRole = useSelector((state) => state.roleReducer.userRole);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const taskId = searchParams.get('taskid');

    console.log("received taskid", taskId);

    useEffect(() => {
        fetchUserData();
        fetchPastActivity();
    }, []);

    const fetchUserData = async () => {
        setIsLoader(true);
        const requestBody = { id: taskId };
        const method = 'POST';
        let url = `${BASE_URL}/partner/taskdetails`;

        const token = await userToken;
        const wrongResponse = () => {
            setAlertMessage({ success: '', error: 'Unable to fetch parameters' });
        }
        const rightResponse = (data) => {
            setTaskDetails(data.task);
            setIsLoader(false);
        };
        handleDynamicRequestHeader(method, url, requestBody, token, wrongResponse, rightResponse)
    }
    useEffect(() => {
        setTimeout(() => {
            setIsLoader(false);
        }, 2000);
    });

    console.log(taskDetails)

    useEffect(() => {

    }, [taskDetails]);




    const fetchPastActivity = async () => {

        const requestBody = {};
        const method = 'POST';
        const url = `${BASE_URL}/mobile/pastActivityDetails`;
        const token = await userToken;
        const wrongResponse = () => {
            setAlertMessage({ success: '', error: 'Unable to fetch parameters' });
        }
        const rightResponse = (data) => {
            setPassActivity(data);

        };
        handleDynamicRequestHeader(method, url, requestBody, token, wrongResponse, rightResponse)
    }

    const handleBackToActivity = () => {
        return navigate('/partner');
    };

    // Convert the date string to a Date object
    const taskDate = new Date(taskDetails.Date);

    // Get the day of the month
    const dayOfMonth = taskDate.getDate();

    // Define an array to map suffixes for the day of the month
    const suffixes = ["th", "st", "nd", "rd"];

    // Get the appropriate suffix for the day of the month
    let suffix;
    if (dayOfMonth >= 11 && dayOfMonth <= 13) {
        suffix = "th";
    } else {
        suffix = suffixes[dayOfMonth % 10] || "th";
    }

    // Format the date to "7th April 2024" format
    const formattedDate = `${dayOfMonth}${suffix || ''} ${taskDate.toLocaleDateString('en-US', {
        month: 'long',
        year: 'numeric'
    })}`;


    return (
        <div>
            {isLoader ? (
                <Loader />
            ) : (
                <CommonLayout>
                    <div className="left-navigation">
                        <Sidebar></Sidebar>
                    </div>
                    <div className="right-content">
                        <Navbar handleBackClick={handleBackToActivity}></Navbar>
                        <div className="just-block "> Task Details </div>

                        {taskDetails && (
                            <>
                                <section className="user-profile-content">
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="profile-sec">
                                                <div className="choose-file-profile">
                                                    <img src={taskDetails.pic} alt="" />
                                                    <input type="file" />
                                                    {/* <span className="choose-file"></span> */}
                                                </div>
                                                <div className="user-profile-details-info">
                                                    <h2>{taskDetails.schoolname}</h2>
                                                    <span className='text-white'>{taskDetails.address}</span>
                                                    <br />
                                                    <span className='text-white pt-3'>{taskDetails.schoolEmail}</span>
                                                    <p> Principal : {taskDetails.prinpleName} - {taskDetails.schoolContactNo}</p>
                                                    {/* <p>{userDetails.user.schoolContactNo}</p> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 lg:border-start ">
                                            <div className="p-4">
                                                <div className=" text-white ">
                                                    <h2>{taskDetails.studentNo} Students</h2>
                                                    <p>Class : {taskDetails.Class}</p>
                                                    <p>{formattedDate}, {taskDetails.time}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </>
                        )}
                    </div>
                </CommonLayout>
            )}
        </div>
    )
}

export default TaskMaster
