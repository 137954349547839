import React, { useState, useEffect } from 'react';
import CommonHome from '../../components/common/CommonHome'
import { BASE_URL } from '../../components/common/AppConfig';
import { setUserId, setUserRole, setUserName, setJwtToken, setO_photo, setStudent, setNewRegister } from '../../store/Role/action';
import { handleDynamicRequest } from '../../components/common/DyanamicRequest';
import Select from "react-select";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SetAlert from '../../components/common/SetAlert'
function Register() {
  const [isSchoolSelected, setIsSchoolSelected] = useState(false);
  const [isCollegeSelected, setIsCollegeSelected] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
  const [school, setSchool] = useState('');
  const [college, setCollege] = useState('');
  const [submit, setSubmit] = useState(false);
  const [cities, setCities] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    name: '',
    email: '',
    type: '',
    schoolname: '',
    Class: '',
    section: ''
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const fetchOrganisationData = async () => {

    const requestBody = {};

    const method = 'POST';
    const url = `${BASE_URL}/mobile/getAllSchoolInfo`;
    const wrongResponse = (data) => {
      if (data.message)

        setAlertMessage({ success: '', error: data.message });
    }
    const rightResponse = (data) => {
      if (data.schools && data.schools.length > 0) {
        console.log(data)
        setSchool(data);


      } if (data.colleges && data.colleges.length > 0) {
        setCollege(data.colleges)
      }
    }
    handleDynamicRequest(method, url, requestBody, wrongResponse, rightResponse)
  }

  const fetchCities = async () => {

    const method = 'POST';
    const requestBody = { country: 'india' };
    const url = `https://countriesnow.space/api/v0.1/countries/cities`;
    const wrongResponse = (data) => {
      setAlertMessage({ success: '', error: 'Failed to fetch cities' });
    }
    const rightResponse = (data) => {
      if (!data.error) {
        setCities(data.data.map(city => ({ value: city, label: city })));
      } else {
        setAlertMessage({ success: '', error: 'Failed to fetch cities' });
      }
    }
    handleDynamicRequest(method, url, requestBody, wrongResponse, rightResponse)
  };

  useEffect(() => {
    fetchOrganisationData();
    fetchCities();
  }, []);


  const handleSubmit = async (event) => {

    event.preventDefault();

    const requestBody = {
      name: formData.name,
      email: formData.email,
      mobileNo: formData.mobile,
      city: formData.city,
    };


    const method = 'POST';

    const url = `${BASE_URL}/auth/partnersignup`;
    const wrongResponse = (data) => {
      if (data && data.message) {
        console.log(data);
        setAlertMessage({ success: '', error: data.message });
      } else {
        setAlertMessage({ success: '', error: "Account with same email already exists" });
      }
    }
    const rightResponse = (data) => {

      // dispatch(setNewRegister(true));
      // dispatch(setUserRole(data.role));
      // dispatch(setUserName(data.name));
      // dispatch(setJwtToken(data.token));
      navigate('/')
    }
    handleDynamicRequest(method, url, requestBody, wrongResponse, rightResponse)
  }

  const handleCloseProfile = () => {
    navigate('/profile');
  }
  return (
    <CommonHome>
      <div className="create-account-middle-content">
        <div className="create-account-info">
          <h1>Create Account</h1>
          <p>Please enter details below</p>
          <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
          <form className="create-account-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-12">
                <label>Name</label>
                <input
                  className="input"
                  value={formData.name}
                  onChange={handleInputChange}
                  type="text"
                  placeholder="Enter full name"
                  name="name"
                />
              </div>

              <div className="col-lg-12">
                <label>Email</label>
                <input className="input" name='email' value={formData.email} onChange={handleInputChange} type="email" placeholder="Enter your mail id" />
              </div>

              <div className="col-lg-12">
                <label>Mobile Number</label>
                <input className="input" name='mobile' value={formData.mobile} onChange={handleInputChange} type="tel" pattern="[0-9]{10}" title="Please enter a 10-digit number" placeholder="Enter your 10-digit mobile number" />
              </div>

              <div className="col-lg-12">
                <label>City</label>
                <Select
                  options={cities}
                  onChange={(selectedOption) => setFormData({ ...formData, city: selectedOption.value })}
                />
              </div>

              {/* <div className="col-lg-12">
                <label>State</label>
                <input className="input" name='state' value={formData.state} onChange={handleInputChange} type="text" placeholder="Enter your state" />
              </div> */}

              <div className="mt-2 col-lg-12">
                <button className={`request-otp`} disabled={submit} type='submit' >Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </CommonHome>


  )
}

export default Register
